<template>
  <div class="page-element">
    <el-pagination
      :currentPage="currentPage"
      :page-size="pageSize"
      :total="total"
      :background="true"
      layout="prev, pager, next, jumper"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 100
    }
  },
  methods: {
    // 页数切换
    handleCurrentChange(current) {
      this.$emit('changePage', current)
    }
  }
}
</script>

<style scoped>
.page-element {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
}
</style>
