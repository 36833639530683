<template>
  <div class="article-manuscript">
    <el-container>
      <el-header class="head-search">
        <el-row :gutter="24" style="width: 100%; text-align: right">
          <el-col :span="10" style="text-align: left">
            <el-button
              color="#836FFF"
              style="color: white"
              @click="this.$router.push({ name: 'WriteNotes' })"
            >
              <icon icon-name="EditPen" icon-size="20" icon-title="编 写"></icon>
            </el-button>
          </el-col>
          <el-col :span="6"></el-col>
          <el-col :span="6">
            <el-input
              clearable
              show-word-limit
              maxlength="100"
              :prefix-icon="Search"
              v-model="search.keyword"
              placeholder="请输入关键字进行检索！"
              @input="searchPage"
            />
          </el-col>
          <el-col :span="2" style="text-align: left">
            <el-button type="primary" @click="searchPage">
              <icon icon-name="Search" icon-size="20" icon-title="查 询"></icon>
            </el-button>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-table :data="tableData" :highlight-current-row="true">
          <el-table-column label="时 间" header-align="center" width="200">
            <template #default="scope">
              <el-icon>
                <component :is="Clock"></component>
              </el-icon>
              <span style="margin-left: 10px">
                {{ scope.row.modifyTime }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="标 题" header-align="center">
            <template #default="scope">
              <el-icon>
                <component :is="Notebook"></component>
              </el-icon>
              <span style="margin-left: 10px; user-select: text">
                {{ scope.row.title }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="200" label="操 作" align="center">
            <template #default="scope">
              <el-button size="small" type="warning" @click="queryContent(scope.row.serial)">
                <icon icon-name="Edit" icon-size="20" icon-title="编 辑"></icon>
              </el-button>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                :icon="InfoFilled"
                icon-color="red"
                title="确定删除选项?"
                @confirm="deleteManuscript(scope.row.serial)"
              >
                <template #reference>
                  <el-button size="small" type="danger">
                    <icon icon-name="Delete" icon-size="20" icon-title="删 除"></icon>
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer>
        <page
          @changePage="queryPage"
          :pageSize="page.size"
          :currentPage="page.current"
          :total="page.total"
        ></page>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { Clock, InfoFilled, Notebook, Search } from '@element-plus/icons-vue'
import icon from '@/components/icon'
import page from '@/components/page'
import { pageManuscript, deleteManuscript } from '@/api/ArticleApi'

export default {
  name: 'ArticleManuscript',
  components: {
    icon,
    page
  },
  setup() {
    return {
      Search,
      Clock,
      Notebook,
      InfoFilled
    }
  },
  data() {
    return {
      search: {
        keyword: ''
      },
      page: {
        size: 13,
        current: 1,
        total: 0
      },
      tableData: []
    }
  },
  methods: {
    searchPage() {
      this.queryPage()
    },
    queryPage(current) {
      pageManuscript({
        title: this.search.keyword,
        pageNum: typeof current !== 'undefined' ? current : 1,
        pageSize: this.page.size
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.rows
        }
      })
    },
    queryContent(serial) {
      this.$router.push({ name: 'RedactManuscript', query: { serial: serial } })
    },
    deleteManuscript(serial) {
      deleteManuscript({
        serial: serial
      }).then((res) => {
        if (res.code === 200) {
          this.queryPage()
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    }
  },
  mounted() {
    this.queryPage()
  }
}
</script>

<style scoped>
.article-manuscript {
  width: 100%;
  min-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 5px;
}

.head-search {
  width: 100%;
  min-width: 700px;
  display: flex;
  align-items: center;
}

.article-manuscript >>> .el-main {
  min-height: 690px;
}
</style>
